import { createRouter, createWebHistory } from 'vue-router'
import tool from "@/utils/tool";

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home/index')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/user/login.vue'),
    meta: {
      title: "用户登录"
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/user/register.vue'),
    meta: {
      title: "用户注册"
    }
  },
  {
    path: '/send-reset',
    name: 'send_reset',
    component: () => import('@/views/user/send_reset.vue'),
    meta: {
      title: "发送重置请求"
    }
  },
  {
    path: '/reset-password/:code',
    name: 'reset_password',
    component: () => import('@/views/user/reset_password.vue'),
    meta: {
      title: "重置密码"
    }
  },

  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/others/contact.vue'),
    meta: {
      title: "联系方式"
    }
  },

  {
    path: '/other/agreement',
    name: 'agreement',
    component: () => import('@/views/others/agreement.vue'),
    meta: {
      title: "用户协议"
    }
  },
  {
    path: '/other/privacy',
    name: 'privacy',
    component: () => import('@/views/others/privacy.vue'),
    meta: {
      title: "隐私政策"
    }
  },
  {
    path: '/service/:sid',
    name: 'service',
    component: () => import('@/views/service/index'),
    meta: {
      title: "解决方案"
    }
  },
  {
    path: '/jobs',
    name: 'jobs',
    component: () => import('@/views/jobs/index'),
    meta: {
      title: "招聘信息"
    }
  },
  {
    path: '/job/info/:job_id',
    name: 'job_info',
    component: () => import('@/views/jobs/info'),
    meta: {
      title: "招聘信息"
    }
  },
  {
    path: '/articles/:column_id',
    name: 'article_list',
    component: () => import('@/views/article/index'),
    meta: {
      title: "信息列表"
    }
  },
  {
    path: '/article/detail/:article_id',
    name: 'article_detail',
    component: () => import('@/views/article/detail'),
    meta: {
      title: "信息详细"
    }
  },

  {
    path: '/work_order',
    name: 'work_order_list',
    component: () => import('@/views/work_order/index'),
    meta: {
      title: "任务工单信息"
    }
  },
  {
    path: '/work_order/info/:work_order_id',
    name: 'work_order_info',
    component: () => import('@/views/work_order/info'),
    meta: {
      title: "任务工单详情"
    }
  },

  {
    path: '/user/resume',
    name: 'user_resume',
    component: () => import('@/views/user/resume'),
    meta: {
      title: "个人简历"
    }
  },
  {
    path: '/user/profile',
    name: 'user_profile',
    component: () => import('@/views/user/profile'),
    meta: {
      title: "个人资料"
    }
  },
  {
    path: '/user/update_password',
    name: 'update_password',
    component: () => import('@/views/user/update_password'),
    meta: {
      title: "更新密码"
    }
  },

  {
    path: '/user/job/dashboard',
    name: 'user_job_dashboard',
    component: () => import('@/views/user/job/dashboard'),
    meta: {
      title: "求职个人中心"
    }
  },
  {
    path: '/user/job/apply_list',
    name: 'user_job_apply_list',
    component: () => import('@/views/user/job/apply_list'),
    meta: {
      title: "申请列表"
    }
  },

  {
    path: '/user/staff',
    name: 'user_staff',
    component: () => import('@/views/user/staff/index'),
    meta: {
      title: "员工信息"
    }
  },
  {
    path: '/user/staff/payroll_history',
    name: 'user_staff_payroll_history',
    component: () => import('@/views/user/staff/payroll_history'),
    meta: {
      title: "工资记录"
    }
  },


  {
    path: '/user/work_order/dashboard',
    name: 'user_work_order_dashboard',
    component: () => import('@/views/user/work_order/dashboard'),
    meta: {
      title: "工单查询"
    }
  },

]

const router = createRouter({
  routes,
  history: createWebHistory(process.env.BASE_URL),
})


router.beforeEach(async (to, from, next) => {
  let token = null
  if (tool.data.get("TOKEN")) {
    token = tool.data.get("TOKEN");
  } else if (tool.data.sessionStorageGet("TOKEN")) {
    token = tool.data.sessionStorageGet("TOKEN");
  }

  if (to.meta.title) {
    document.title = to.meta.title + '-深圳人力资源公司-深圳劳务派遣-劳务外包公司-代理招聘RPO-择优人才发展有限公司'
  }

  if (to.path.startsWith('/user')) {
    if (!token) {
      next({
        path: '/login'
      });
      return false;
    }
  }

  next();
})

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})
export default router
