<template>
  <span>
    <el-tag :type="is_future?'default':'info'">{{ timeFromNow }}</el-tag>
  </span>
</template>

<script>
export default {
  props: {
    // 通过prop传入时间戳或Date对象
    time: {
      type: [String, Number, Date],
      required: true
    }
  },
  data() {
    return {
      is_future: true
    }
  },
  computed: {
    timeFromNow() {
      let compareTime = this.time
      if (typeof (compareTime) === 'string') compareTime = new Date(this.time)
      // todo 处理time的 Number 类型

      const now = new Date();
      const diff = now - compareTime;


      if (diff < 0) {
        // 时间在未来
        this.is_future = true
        const seconds = Math.abs(Math.floor(diff / 1000));
        if (seconds < 60) {
          return `${seconds}秒钟后`
        }
        const minutes = Math.abs(Math.floor(diff / (1000 * 60)));
        if (minutes < 60) {
          return `${minutes}分钟后`
        }
        const hours = Math.abs(Math.floor(diff / (1000 * 60 * 60)));
        if (hours < 24) {
          return `${hours}小时后`
        }
        const days = Math.abs(Math.floor(diff / (1000 * 60 * 60 * 24)));
        if (days < 7) {
          return `${days}天后`
        }
        // 其他天数
        return `${days}天后`
      } else {
        // 时间在过去
        this.is_future = false

        const seconds = Math.floor(diff / 1000);
        if (seconds < 60) {
          return `${seconds}秒钟前`
        }
        const minutes = Math.floor(diff / (1000 * 60));
        if (minutes < 60) {
          return `${minutes}分钟前`
        }
        const hours = Math.floor(diff / (1000 * 60 * 60));
        if (hours < 24) {
          return `${hours}小时前`
        }
        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        if (days < 7) {
          return `${days}天前`
        }
        // 其他天数
        return `${days}天前`
      }
    }
  },
  methods: {
    formatDate(date) {
      const d = new Date(date);
      return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
    }
  }
};
</script>

<style lang="scss">
.brows-job-position p span {
  margin-top: 1px !important;
}

</style>
