<template>
  <div class="wrapper">
    <nav-header></nav-header>
    <router-view/>
    <nav-footer></nav-footer>
  </div>
</template>
<script>
import NavHeader from '@/components/nav_header'
import NavFooter from '@/components/nav_footer'

export default {
  name: 'home',
  components: {
    NavHeader,
    NavFooter,
  },
  data() {
    return {
      BASE_URL: process.env.VUE_APP_BASE_URL
    }
  },
  mounted() {
  },
  methods: {}
}
</script>
<style lang="scss">
</style>
