import http from "@/utils/request"

export default {
    getArticleList: {
        url: `${process.env.VUE_APP_BASE_URL}/portal/article/list`,
        name: "查询文章列表",
        post: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },
    getArticleDetail: {
        url: `${process.env.VUE_APP_BASE_URL}/portal/article/detail`,
        name: "查询文章详情",
        post: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },
}
