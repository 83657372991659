import http from "@/utils/request"

export default {
	get_signature: {
		url: `${process.env.VUE_APP_BASE_URL}/portal/wechat/get_signature`,
		name: "签名",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},
}
