import http from "@/utils/request"

export default {
	token: {
		url: `${process.env.VUE_APP_BASE_URL}/portal/user/login`,
		name: "登录获取TOKEN",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	dict: {
		url: `${process.env.VUE_APP_BASE_URL}/common/dict/list`,
		name: "获取字典",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
}
