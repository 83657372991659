import http from "@/utils/request"

export default {
    // 人才基本信息
    getTalentProfile: {
        url: `${process.env.VUE_APP_BASE_URL}/portal/talent/profile`,
        name: "查询人才基本信息",
        post: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },
    updateTalentProfile: {
        url: `${process.env.VUE_APP_BASE_URL}/portal/talent/profile/update`,
        name: "修改人才基本信息",
        post: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },
    // 人才求职信息
    getTalentApply: {
        url: `${process.env.VUE_APP_BASE_URL}/portal/talent/apply`,
        name: "查询人才求职信息",
        post: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },
    updateTalentApply: {
        url: `${process.env.VUE_APP_BASE_URL}/portal/talent/apply/update`,
        name: "修改人才求职信息",
        post: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },

    //人才工作经验
    getTalentExperienceList: {
        url: `${process.env.VUE_APP_BASE_URL}/portal/talent/experience/list`,
        name: "查询人才经验",
        post: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },
    submitTalentExperience: {
        url: `${process.env.VUE_APP_BASE_URL}/portal/talent/experience/save`,
        name: "新增人才经验",
        post: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },
    delTalentExperience: {
        url: `${process.env.VUE_APP_BASE_URL}/portal/talent/experience/del`,
        name: "删除人才教育经历",
        post: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },

    //人才教育经验
    getTalentEducationList: {
        url: `${process.env.VUE_APP_BASE_URL}/portal/talent/education/list`,
        name: "查询人才教育经历",
        post: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },
    submitTalentEducation: {
        url: `${process.env.VUE_APP_BASE_URL}/portal/talent/education/save`,
        name: "新增人才教育经历",
        post: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },
    delTalentEducation: {
        url: `${process.env.VUE_APP_BASE_URL}/portal/talent/education/del`,
        name: "删除人才教育经历",
        post: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },


    //人才技能
    getTalentSkillList: {
        url: `${process.env.VUE_APP_BASE_URL}/portal/talent/skill/list`,
        name: "查询人才技能",
        post: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },
    submitTalentSkill: {
        url: `${process.env.VUE_APP_BASE_URL}/portal/talent/skill/save`,
        name: "新增人才技能",
        post: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },
    delTalentSkill: {
        url: `${process.env.VUE_APP_BASE_URL}/portal/talent/skill/del`,
        name: "删除人才技能",
        post: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },


    //人才技能
    getTalentAwardList: {
        url: `${process.env.VUE_APP_BASE_URL}/portal/talent/award/list`,
        name: "查询人才荣誉",
        post: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },
    submitTalentAward: {
        url: `${process.env.VUE_APP_BASE_URL}/portal/talent/award/save`,
        name: "新增人才荣誉",
        post: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },
    delTalentAward: {
        url: `${process.env.VUE_APP_BASE_URL}/portal/talent/award/del`,
        name: "删除人才荣誉",
        post: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },

    // 人才附件

    getTalentAttachmentList: {
        url: `${process.env.VUE_APP_BASE_URL}/portal/talent/attachment/list`,
        name: "查询人才附件",
        post: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },
    saveTalentAttachment: {
        url: `${process.env.VUE_APP_BASE_URL}/portal/talent/attachment/save`,
        name: "保存人才附件",
        post: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },
    removeTalentAttachment: {
        url: `${process.env.VUE_APP_BASE_URL}/portal/talent/attachment/remove`,
        name: "删除人才附件",
        post: async function (data = {}) {
            return await http.post(this.url, data);
        }
    }
}
