import http from "@/utils/request"

export default {
	getJobList: {
		url: `${process.env.VUE_APP_BASE_URL}/portal/job/post/list`,
		name: "查询招聘列表",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	getJobInfo: {
		url: `${process.env.VUE_APP_BASE_URL}/portal/job/post/info`,
		name: "查询招聘信息",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	applyJob: {
		url: `${process.env.VUE_APP_BASE_URL}/portal/job/apply`,
		name: "查询招聘信息",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},
}
