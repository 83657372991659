import http from "@/utils/request"

export default {
    registerTalent: {
        url: `${process.env.VUE_APP_BASE_URL}/portal/user/talent/create`,
        name: "用户注册",
        post: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },
    updateProfile: {
        url: `${process.env.VUE_APP_BASE_URL}/portal/user/profile/update`,
        name: "用户个人信息修改",
        post: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },
    updatePassword: {
        url: `${process.env.VUE_APP_BASE_URL}/portal/user/password/update`,
        name: "用户修改个人密码",
        post: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },
    sendResetRequest: {
        url: `${process.env.VUE_APP_BASE_URL}/portal/user/send_reset_mail`,
        name: "发送重置邮件",
        post: async function(data){
            return await http.post(this.url, data);
        }
    },
    submitResetRequest: {
        url: `${process.env.VUE_APP_BASE_URL}/portal/user/url_reset_password`,
        name: "通过链接重置密码",
        post: async function(data){
            return await http.post(this.url, data);
        }
    },
}
