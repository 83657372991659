<template>
  <footer class="dark-footer skin-dark-footer">
    <div>
      <div class="container">
        <div class="row">

          <div class="col-lg-3 col-md-3">
            <div class="footer-widget" style="padding: 60px 0">
              <a href="/"><img src="/img/logo-white.png" class="img-footer" alt="首页"/></a>
<!--              <div class="footer-add">-->
<!--                <p>Collins Street West, Victoria,<br/> Australia (AU4578).</p>-->
<!--                <p><strong>Email:</strong><br/>hello@jobstock.com</p>-->
<!--                <p><strong>Call:</strong><br/>91 855 742 62548</p>-->
<!--              </div>-->

            </div>
          </div>

          <div class="col-lg-2 col-md-2">
            <div class="footer-widget">
              <h4 class="widget-title">外包服务产品</h4>
              <ul class="footer-menu">
                <li><a href="/service/101">人事管理</a></li>
                <li><a href="/service/102">人员派遣</a></li>
                <li><a href="/service/103">岗位外包</a></li>
                <li><a href="/service/104">员工福利</a></li>
              </ul>
            </div>
          </div>

          <div class="col-lg-2 col-md-2">
            <div class="footer-widget">
              <h4 class="widget-title">企业解决方案</h4>
              <ul class="footer-menu">
                <li><a href="/service/201">招聘服务</a></li>
                <li><a href="/service/202">咨询培训</a></li>
                <li><a href="/service/204">残疾人就业</a></li>
              </ul>
            </div>
          </div>

          <div class="col-lg-2 col-md-2">
            <div class="footer-widget">
              <h4 class="widget-title">更多</h4>
              <ul class="footer-menu">
                <li><a href="/register">注册</a></li>
                <li><a href="/login">登录</a></li>
                <li><a href="/user/profile">个人中心</a></li>
                <li><a href="/jobs">招聘信息</a></li>
                <li><a href="/contact">联系我们</a></li>
              </ul>
            </div>
          </div>

          <div class="col-lg-3 col-md-3">
            <div class="footer-widget">
              <h4 class="widget-title">关注我们</h4>
              <img src="@/assets/img/qrcode.png" width="180" height="180"/>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="footer-bottom">
      <div class="container">
        <div class="row align-items-center">

          <div class="col-lg-10 col-md-12">
            <p class="mb-0">&copy; 2021 深圳市择优人才发展有限公司 版权所有</p>
          </div>

          <div class="col-lg-2 col-md-12">
            <ul class="footer-bottom-social">
              <li><a href="https://beian.miit.gov.cn" target="_blank">粤ICP备2022085333号</a></li>
            </ul>
          </div>

        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "NavFooter"
}
</script>

<style scoped>

</style>
